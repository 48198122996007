import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  InputNumber,
  Select,
} from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { SaveOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};
class ConnectedCreateOrUpdateKoneLevelModal extends Component {
  formRef = React.createRef();

  state = {
    modalVisible: this.props.modalVisible || false,
    modalMode: "create",
    selectedLevel: null,
    modify: false,
    selectedId: null,
    name: "",
    isPublic: false,
    isExit: false,
    isAbleToInvite: false,
    userToAddressVisible: false,
    maxStartTolerance: 15,
    maxEndTolerance: 0,
    targetKeys: [],
    selectedKeys: [],
    companyName: "",
    baseTime: null,
    addressBaseTime: null,
    anonymizationTime: 30,
    officeAnonymizationTimeLimit: 30,
    companyId: null,
    addCompany: false,
    defaultKoneAreaId: null,
  };

  componentDidMount = async () => {
    this.setState({
      modalVisible: this.props.modalVisible,
      modalMode: this.props.modalMode,
    });
  };

  componentDidUpdate = () => {
    if (this.props.modalVisible !== this.state.modalVisible) {
      this.resetValues();

      this.setState({
        modalVisible: this.props.modalVisible,
        modalMode: this.props.modalMode,
      });

      if (this.props.selectedLevel) {
        const selectedLevel = this.props.selectedLevel;
        this.setState({
          selectedLevel: selectedLevel,
          name: selectedLevel.name,
          isPublic: selectedLevel.isPublic,
          isExit: selectedLevel.isExit,
          isAbleToInvite: selectedLevel.isAbleToInvite,
          maxStartTolerance: selectedLevel.beforeStartToleranceMins,
          maxEndTolerance: selectedLevel.beforeEndToleranceMins,
          baseTime: selectedLevel.baseTime,
          addCompany: selectedLevel.companyId ? true : false,
          companyId: selectedLevel.companyId
            ? selectedLevel.companyId
            : null,
          defaultKoneAreaId: selectedLevel.defaultKoneAreaId ? Number(selectedLevel.defaultKoneAreaId) : null,
        });

        this.formRef.current.setFieldsValue({
          name: selectedLevel.name,
          isPublic: selectedLevel.isPublic,
          isExit: selectedLevel.isExit,
          isAbleToInvite: selectedLevel.isAbleToInvite,
          maxStartTolerance: selectedLevel.beforeStartToleranceMins,
          maxEndTolerance: selectedLevel.beforeEndToleranceMins,
          baseTime: selectedLevel.baseTime,
          addCompany: selectedLevel.companyId ? true : false,
          companyId: selectedLevel.companyId
            ? selectedLevel.companyId
            : null,
          defaultKoneAreaId: selectedLevel.defaultKoneAreaId ? Number(selectedLevel.defaultKoneAreaId) : null,
        });
      }
    }
  };

  handleCancel = () => {
    this.resetValues();
    this.props.handleCloseCreateOrUpdateElevatorLevelModal();
  };

  resetValues = () => {
    this.setState({
      selectedLevel: null,
      modify: false,
      selectedId: null,
      name: "",
      isPublic: false,
      isOutsideGate: false,
      isExit: false,
      isAbleToInvite: false,
      userToAddressVisible: false,
      maxStartTolerance: 15,
      maxEndTolerance: 0,
      baseTime: null,
      addCompany: false,
      companyId: null,
      defaultKoneAreaId: null,
    });

    this.formRef.current.resetFields();
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChangeCheckbox = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  submitForm = async () => {
    const levelData = {
      id: this.state.selectedLevel ? this.state.selectedLevel.id : null,
      companyId:
        this.state.companyId && this.state.addCompany
          ? this.state.companyId
          : null,
      officeId: this.props.user.officeId,
      name: this.state.name,
      isPublic: this.state.isPublic,
      isExit: this.state.isExit,
      isAbleToInvite: this.state.isAbleToInvite,
      maxStartTolerance: this.state.maxStartTolerance
        ? parseInt(this.state.maxStartTolerance)
        : null,
      maxEndTolerance: parseInt(this.state.maxEndTolerance),
      anonymizationTime:
        this.state.anonymizationTime === null
          ? this.props.office.anonymizationTime
          : this.state.anonymizationTime,
      defaultKoneAreaId: this.state.defaultKoneAreaId ? Number(this.state.defaultKoneAreaId) : null,
    };

    if (this.state.modalMode === "create") {
      await this.props.createNewLevel(levelData);
    } else {
      await this.props.updateLevel(levelData);
    }

    if (this.props.saveStatus) {
      message.success(this.props.t("save-success"), 5);
      this.handleCancel();
    } else {
      message.error(this.props.t("save-fail"), 5);
    }
  };

  onAnonymizationTimeChange = (value) => {
    this.setState({
      anonymizationTime: value,
    });
  };

  onSelectedCompanyChange = async (event) => {
    this.setState({
      companyId: event,
    });
  };

  handleChangeTarget = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  render() {
    return (
      <>
        <Modal
          visible={this.state.modalVisible}
          title={
            this.state.modalMode === "create"
              ? this.props.t("entrypoints-create-title")
              : this.props.t("entrypoints-update-title")
          }
          onCancel={this.handleCancel}
          width={1000}
          footer={null}
          forceRender
        >
          <Form
            ref={this.formRef}
            {...formItemLayout}
            name="company"
            onFinish={this.submitForm}
            scrollToFirstError
            layout="vertical"
            wrapperCol={{ style: { padding: "0", margin: "0 0 0 0" } }}
          >
            <Row>
              <Col span={12} style={{ padding: "0.5em" }}>
                <Form.Item
                  label={
                    <strong>
                      {this.props.t("companyadmin-lift-name-label")}:
                    </strong>
                  }
                  name="name"
                  initialValue={this.state.name}
                  rules={[
                    {
                      required: true,
                      message: this.props.t("companyadmin-lift-name-label"),
                    },
                  ]}
                >
                  <Input
                    name="name"
                    onChange={this.handleChange}
                    value={this.state.name}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ padding: "0.5em" }}>
                <Form.Item
                  label={
                    <strong>
                      {this.props.t(
                        "companyadmin-address-starttolerance-label"
                      )}
                    </strong>
                  }
                  name="maxStartTolerance"
                  initialValue={this.state.maxStartTolerance}
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "companyadmin-address-starttolerance-text"
                      ),
                    },
                  ]}
                >
                  <Input
                    name="maxStartTolerance"
                    onChange={this.handleChange}
                    value={this.state.maxStartTolerance}
                    type="number"
                    step={1}
                  />
                </Form.Item>
              </Col>
              <Col span={6} style={{ padding: "0.5em" }}>
                <Form.Item
                  label={
                    <strong>
                      {this.props.t("companyadmin-address-endtolerance-label")}
                    </strong>
                  }
                  name="maxEndTolerance"
                  initialValue={this.state.maxEndTolerance}
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "companyadmin-address-endtolerance-text"
                      ),
                    },
                  ]}
                >
                  <Input
                    name="maxEndTolerance"
                    onChange={this.handleChange}
                    value={this.state.maxEndTolerance}
                    type="number"
                    step={1}
                  />
                </Form.Item>
              </Col>
              <Col span={6} style={{ padding: "0.5em" }}>
                <Form.Item
                  label={
                    <strong>
                      {this.props.t("companyadmin-anonymizationTime")}
                    </strong>
                  }
                  name="anonymizationTime"
                  initialValue={this.state.anonymizationTime}
                  rules={[
                    {
                      required: true,
                      message: this.props.t("companyadmin-anonymizationTime"),
                    },
                  ]}
                >
                  <InputNumber
                    min={this.state.officeAnonymizationTimeLimit}
                    value={this.state.anonymizationTime}
                    onChange={this.onAnonymizationTimeChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ padding: "0.5em" }}>
                <Form.Item name="isPublic" initialValue={this.state.isPublic}>
                  <Checkbox
                    id="addCompany"
                    name="addCompany"
                    onChange={this.handleChangeCheckbox}
                    checked={this.state.addCompany}
                  />
                  <label
                    htmlFor="addCompany"
                    style={{ marginLeft: "1em", color: "#262626" }}
                  >
                    <strong>{this.props.t("addresses-add-company")}</strong>
                  </label>
                </Form.Item>
              </Col>
              <Col span={6} style={{ padding: "0.5em" }}>
                <Form.Item
                  label={<strong>{this.props.t("companies-title")}</strong>}
                  name="companyId"
                >
                  <Select
                    style={{ width: "15em" }}
                    onChange={(event) => this.onSelectedCompanyChange(event)}
                    disabled={!this.state.addCompany}
                  >
                    {this.props.companies.map((company) => {
                      return (
                        <Select.Option key={company.id} value={company.id}>
                          {company.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ padding: "0.5em" }}>
                <Form.Item name="isPublic" initialValue={this.state.isPublic}>
                  <Checkbox
                    id="isPublic"
                    name="isPublic"
                    onChange={this.handleChangeCheckbox}
                    checked={this.state.isPublic}
                  />
                  <label
                    htmlFor="isPublic"
                    style={{ marginLeft: "1em", color: "#262626" }}
                  >
                    <strong>
                      {this.props.t("companyadmin-address-public-label")}
                    </strong>
                  </label>
                </Form.Item>
              </Col>
              <Col span={6} style={{ padding: "0.5em" }}>
                <Form.Item
                  name="isAbleToInvite"
                  initialValue={this.state.isAbleToInvite}
                >
                  <Checkbox
                    id="isAbleToInvite"
                    name="isAbleToInvite"
                    onChange={this.handleChangeCheckbox}
                    checked={this.state.isAbleToInvite}
                  />
                  <label
                    htmlFor="isAbleToInvite"
                    style={{ marginLeft: "1em", color: "#262626" }}
                  >
                    <strong>
                      {this.props.t("companyadmin-address-abletoinvite-label")}
                    </strong>
                  </label>
                </Form.Item>
              </Col>
              <Col span={6} style={{ padding: "0.5em" }}>
                <Form.Item name="isExit" initialValue={this.state.isExit}>
                  <Checkbox
                    id="isExit"
                    name="isExit"
                    onChange={this.handleChangeCheckbox}
                    checked={this.state.isExit}
                    disabled={!this.state.isOutsideGate}
                  />
                  <label
                    style={{ marginLeft: "1em", color: "#262626" }}
                    htmlFor="isExit"
                  >
                    <strong>
                      {this.props.t("companyadmin-address-exit-label")}
                    </strong>
                  </label>
                </Form.Item>
              </Col>
              <Col span={4} style={{ padding: "0.5em" }}>
                <Form.Item
                  label={
                    <strong>
                      {this.props.t(
                        "companyadmin-address-default-kone-area-id-label"
                      )}
                    </strong>
                  }
                  name="defaultKoneAreaId"
                  initialValue={this.state.defaultKoneAreaId}
                  rules={[]}
                >
                  <Input
                    name="defaultKoneAreaId"
                    type="number"
                    onChange={this.handleChange}
                    value={this.state.defaultKoneAreaId}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item style={{ textAlign: "right" }}>
              <Button
                style={{ marginRight: "10px" }}
                onClick={this.handleCancel}
              >
                {this.props.t("cancel-button-label")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                //disabled={this.props.company[0]?.officeId}
              >
                <SaveOutlined /> {this.props.t("save-button-label")}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
});
const CreateOrUpdateKoneLevelModal = withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConnectedCreateOrUpdateKoneLevelModal)
);
export default CreateOrUpdateKoneLevelModal;

import Users from "../components/Users/users";
import Emergency from "../components/Emergency/emergency";
import Subscription from "../components/Subscription/subscription";
import Profile from "../components/Users/profile";
import ReceptionMain from "../components/Reception/receptionMain";
import UnderConstruction from "../components/UnderConstruction/underconstruction";
import Messages from "../components/Messages/messages";
import Help from "../components/Help/help";
import RegAppTokens from "../components/RegAppTokens/regAppTokens";
import CompanyDesign from "../components/CompanyAdmin/companydesign";
import CompanyAddresses from "../components/CompanyAdmin/companyAddresses";
import CompanyAgreements from "../components/CompanyAdmin/companyagreements";
import CompanyHrColleagues from "../components/CompanyAdmin/companyHrColleagues";
import CompanyReceptionColleagues from "../components/CompanyAdmin/companyReceptionColleagues";
import { subscriptionIdExceptions } from "./subscriptionIdExceptions";
import WorkingUsers from "../components/Users/working-users";
import Office from "../components/Office/office";
import UserProximityCards from "../components/ProximityCard/proximitycard";
import ParkingGroups from "../components/Parking/groups";
import ParkingGates from "../components/Parking/gates";
import Park from "../components/Parking/park";
import ManualEnter from "../components/Parking/manualEnter";
import { userRoles } from "./roles-types";
import GuestList from "../components/Guest/guest-list";
import Vehicles from "../components/Parking/vehicles";
import CustomPushNotification from "../components/CustomPushNotification/customPushNotification";
import CompaniesPage from "../pages/Companies/companies.page";
import EntryPointsPage from "../pages/EntryPoints/entrypoints.page";
import CompanyAdressesPage from "../pages/Addresses/companyAddresses.page";
import OfficeAddressesPage from "../pages/Addresses/officeAddresses.page";
import BuildingLayoutPage from "../pages/Buildings/buildingLayout.page";
import BuildingUserPermissionPage from "../pages/Buildings/buildingUserPermission.page";
import BuildingRoomsPage from "../pages/Buildings/buildingRooms.page";
import Workers from "../components/Users/workers";
import ParkingExportPage from "../pages/Parkings/parking-export.page";
import KoneLiftsPage from "../pages/KoneLifts/koneLifts.page";

export const routes = [
  {
    path: "/tl/system-admins",
    component: Users,
    rights: [userRoles.SYSTEM_ADMIN],
    groupsToLoad: [userRoles.SYSTEM_ADMIN],
  },
  {
    path: "/tl/office-admins",
    component: Users,
    rights: [userRoles.SYSTEM_ADMIN, userRoles.OFFICE_ADMIN],
    groupsToLoad: [userRoles.OFFICE_ADMIN],
  },
  {
    path: "/tl/company-admins",
    component: Users,
    rights: [userRoles.SYSTEM_ADMIN],
    groupsToLoad: [userRoles.COMPANY_ADMIN],
  },
  {
    path: "/tl/custom-push-notification",
    component: CustomPushNotification,
    rights: [userRoles.SYSTEM_ADMIN],
    groupsToLoad: [userRoles.SYSTEM_ADMIN],
  },
  {
    path: "/tl/users",
    component: Users,
    rights: [userRoles.SYSTEM_ADMIN],
    groupsToLoad: [
      userRoles.RECEPTION_ADMIN,
      userRoles.HR_ADMIN,
      userRoles.USER,
    ],
  },
  {
    path: "/tl/companies",
    component: CompaniesPage,
    rights: [userRoles.SYSTEM_ADMIN, userRoles.OFFICE_ADMIN],
  },
  {
    path: "/tl/building/layout",
    component: BuildingLayoutPage,
    rights: [userRoles.SYSTEM_ADMIN, userRoles.OFFICE_ADMIN],
  },
  {
    path: "/tl/building/permission/user",
    component: BuildingUserPermissionPage,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.OFFICE_ADMIN,
      userRoles.COMPANY_ADMIN,
    ],
  },
  {
    path: "/tl/building/rooms",
    component: BuildingRoomsPage,
    rights: [userRoles.SYSTEM_ADMIN, userRoles.OFFICE_ADMIN],
  },
  {
    path: "/tl/administration",
    component: UnderConstruction,
    rights: [userRoles.SYSTEM_ADMIN],
  },
  {
    path: "/tl/emergency",
    component: Emergency,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.OFFICE_ADMIN,
    ],
    notAllowedSubscriptions: subscriptionIdExceptions.workExceptionIds,
  },
  {
    path: "/tl/subscriptions",
    component: Subscription,
    rights: [userRoles.SYSTEM_ADMIN],
  },
  {
    path: "/tl/workers",
    component: Workers,
   // component: WorkersPage,
    rights: [
      userRoles.OFFICE_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.HR_ADMIN,
    ],
  },
  {
    path: "/tl/reg-app-tokens",
    component: RegAppTokens,
    rights: [userRoles.COMPANY_ADMIN, userRoles.OFFICE_ADMIN],
  },
  {
    path: "/tl/design",
    component: CompanyDesign,
    rights: [userRoles.COMPANY_ADMIN, userRoles.OFFICE_ADMIN],
  },
  {
    path: "/tl/addresses",
    component: CompanyAddresses,
    rights: [userRoles.COMPANY_ADMIN],
  },
  {
    path: "/tl/agreements",
    component: CompanyAgreements,
    rights: [userRoles.COMPANY_ADMIN, userRoles.OFFICE_ADMIN],
    notAllowedSubscriptions: subscriptionIdExceptions.inviteExceptionIds,
  },
  {
    path: "/tl/hr-admin",
    component: CompanyHrColleagues,
    rights: [userRoles.COMPANY_ADMIN],
  },
  {
    path: "/tl/reception-admin",
    component: CompanyReceptionColleagues,
    rights: [userRoles.COMPANY_ADMIN],
    notAllowedSubscriptions: subscriptionIdExceptions.inviteExceptionIds,
  },
  {
    path: "/tl/profile",
    component: Profile,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.USER,
      userRoles.OFFICE_ADMIN,
      userRoles.OFFICE_RECEPTION_ADMIN,
      userRoles.PARKING_RECEPTION,
    ],
    groupsToLoad: [
      userRoles.RECEPTION_ADMIN,
      userRoles.HR_ADMIN,
      userRoles.USER,
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.OFFICE_ADMIN,
      userRoles.OFFICE_RECEPTION_ADMIN,
      userRoles.PARKING_RECEPTION,
    ],
  },
  {
    path: "/tl/reception",
    component: ReceptionMain,
    rights: [
      userRoles.COMPANY_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.OFFICE_ADMIN,
      userRoles.OFFICE_RECEPTION_ADMIN,
      userRoles.INVITE_USER_RECEPTION_ADMIN,
    ],
    notAllowedSubscriptions: subscriptionIdExceptions.inviteExceptionIds,
  },
  {
    path: "/tl/reception-guests",
    component: GuestList,
    rights: [
      userRoles.COMPANY_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.OFFICE_ADMIN,
      userRoles.OFFICE_RECEPTION_ADMIN,
      userRoles.HR_ADMIN,
    ],
    notAllowedSubscriptions: subscriptionIdExceptions.inviteExceptionIds,
  },
  {
    path: "/tl/company-admins",
    component: UnderConstruction,
    rights: [userRoles.SYSTEM_ADMIN],
  },
  {
    path: "/tl/users",
    component: UnderConstruction,
    rights: [userRoles.SYSTEM_ADMIN],
  },
  {
    path: "/tl/messages",
    component: Messages,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.HR_ADMIN,
      userRoles.OFFICE_ADMIN,
      userRoles.OFFICE_RECEPTION_ADMIN,
    ],
    notAllowedSubscriptions: subscriptionIdExceptions.workExceptionIds,
  },
  {
    path: "/tl/entrypoints",
    component: EntryPointsPage,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.OFFICE_ADMIN,
      userRoles.OFFICE_RECEPTION_ADMIN,
    ],
  },
  {
    path: "/tl/parking-export",
    component: ParkingExportPage,
    rights: [
      userRoles.OFFICE_ADMIN,
    ],
  },
  {
    path: "/tl/company-addresses",
    component: CompanyAdressesPage,
    rights: [userRoles.COMPANY_ADMIN],
  },
  {
    path: "/tl/office-addresses",
    component: OfficeAddressesPage,
    rights: [userRoles.OFFICE_ADMIN],
  },
  {
    path: "/tl/kone-lifts",
    component: KoneLiftsPage,
    rights: [userRoles.OFFICE_ADMIN, userRoles.COMPANY_ADMIN],
  },
  {
    path: "/tl/help",
    component: Help,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.HR_ADMIN,
      userRoles.USER,
      userRoles.OFFICE_ADMIN,
      userRoles.OFFICE_RECEPTION_ADMIN,
    ],
  },
  {
    path: "/tl/working-users",
    component: WorkingUsers,
    rights: [userRoles.COMPANY_ADMIN, userRoles.HR_ADMIN],
  },
  {
    path: "/tl/offices",
    component: Office,
    rights: [userRoles.SYSTEM_ADMIN, userRoles.OFFICE_ADMIN],
    groupsToLoad: [userRoles.SYSTEM_ADMIN, userRoles.OFFICE_ADMIN],
  },
  {
    path: "/tl/proximity-card",
    component: UserProximityCards,
    rights: [
      userRoles.COMPANY_ADMIN,
      userRoles.RECEPTION_ADMIN,
      userRoles.OFFICE_ADMIN,
      userRoles.OFFICE_RECEPTION_ADMIN,
    ],
  },
  {
    path: "/tl/vehicles",
    component: Vehicles,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.OFFICE_ADMIN,
      userRoles.PARKING_RECEPTION,
    ],
  },
  {
    path: "/tl/parking-groups",
    component: ParkingGroups,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.OFFICE_ADMIN,
    ],
  },
  {
    path: "/tl/parking-gates",
    component: ParkingGates,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.HR_ADMIN,
      userRoles.OFFICE_ADMIN,
      userRoles.PARKING_RECEPTION,
    ],
  },
  {
    path: "/tl/parking-park",
    component: Park,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.COMPANY_ADMIN,
      userRoles.HR_ADMIN,
      userRoles.OFFICE_ADMIN,
    ],
  },
  {
    path: "/tl/manual-enter",
    component: ManualEnter,
    rights: [
      userRoles.SYSTEM_ADMIN,
      userRoles.OFFICE_ADMIN,
      userRoles.PARKING_RECEPTION,
    ],
  },
];
